import React, { useReducer } from "react";
import ProductsContext from "./productsContext";
import ProductsReducer from "./productsReducer";
import { SET_IS_LOADING, SET_INITIAL_DATA } from "../types.js";
import api from "../../apis/api";
import { groupBy, get } from "lodash";

const ProductsState = (props) => {
    const initialState = { products: null, isLoading: false };
    const [state, dispatch] = useReducer(ProductsReducer, initialState);

    React.useEffect(() => {
        getInitialProducts();
    }, []);

    const toggleIsLoading = (bool) =>
        dispatch({ type: SET_IS_LOADING, payload: bool });

    const getInitialProducts = async () => {
        await api.get("/products/").then((response) => {
            const products = get(response, "data", []);
            console.log(
                "🚀 ~ file: ProductsState.js ~ line 14 ~ api.get ~ products",
                groupBy(products, "category")
            );
            dispatch({
                type: SET_INITIAL_DATA,
                payload: groupBy(products, "category"),
            });
        });
    };
    return (
        <ProductsContext.Provider value={{ products: state.products }}>
            {props.children}
        </ProductsContext.Provider>
    );
};

export default ProductsState;
