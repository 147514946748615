import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import { client, hostedFields } from "braintree-web";
import dropin from "braintree-web-drop-in";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";


export default class Braintree extends PureComponent {
  componentDidMount() {
    console.log("creating dropin");
    let button = document.querySelector("#submit-button");
    const token =
      "sandbox_mfd8g5gv_hnjc4ts9t63dx82p";

    dropin.create(
      {
        authorization: token,
        container: "#dropin-container"

      },
      function(createErr, instance) {
        console.log("instance", instance);
        console.log("createErr", createErr);
        button.addEventListener("click", function() {
          instance.requestPaymentMethod(function(
            requestPaymentMethodErr,
            payload
          ) {
            console.log("submitting...");
            // Submit payload.nonce to your server
          });
        });
      }
    );
  }

  render() {
    // console.log("braintree client", client);
    // console.log("braintree hostedFields", hostedFields);
    // console.log("braintree dropin", dropin);

    return (
        <div>
        <div id="dropin-container" />
        <a className="floatright" href="https://www.braintreegateway.com/merchants/hnjc4ts9t63dx82p/verified" target="_blank">
      <img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png" width="280px" height ="44px" border="0"/>
      </a>
        <Button className="floatleft" style={{backgroundColor:"#ecd3b3", color:"#a6712c"}} variant="filled" id="submit-button">Add card</Button>
        </div>
    );
  }
}

