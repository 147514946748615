import React from "react";
import "./styles.css";
import NavBar from "./components/NavBar";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Wallet from "./pages/Wallet";
import Lottie from "lottie-react";
import uni from "./animations/uni.json";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase";
import { FirebaseDatabaseProvider } from "@react-firebase/database";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Backdrop } from "@material-ui/core";
import ProductsMain from "./components/Products/ProductsMain";
import OrdersState from "./context/orders/OrdersState";
import OrderMain from "./components/Orders/OrderMain";
import ScrollToTop from "./components/ScrollToTop";
import CheckoutDashboard from "./components/Checkout/CheckoutDashboard";
import ShippingAddress from "./components/Checkout/ShippingAddress";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "rgb(105, 243, 135)",
        },
        secondary: {
            main: "rgb(105, 243, 135)",
        },
    },
});

export default function App() {
    const [user, loading, error] = useAuthState(firebase.auth());
    if (user) {
        return (
            <MuiThemeProvider theme={theme}>
                <FirebaseDatabaseProvider>
                    <OrdersState>
                        <div className="App">
                            <div style={{ paddingTop: 40 }} />
                            <BrowserRouter>
                                <NavBar />
                                <ScrollToTop />
                                <Switch>
                                    <Route exact path="/">
                                        <Home />
                                    </Route>

                                    <Route path="/login">
                                        <Login />
                                    </Route>

                                    <Route path="/products">
                                        <ProductsMain />
                                    </Route>

                                    <Route path="/orders">
                                        <OrderMain />
                                    </Route>

                                    <Route path="/wallet">
                                        <Wallet />
                                    </Route>

                                    <Route path="/checkout/:id">
                                        <CheckoutDashboard />
                                    </Route>
                                    <Route path="/confirmation/:id">
                                        <ShippingAddress />
                                    </Route>

                                </Switch>
                            </BrowserRouter>
                        </div>
                    </OrdersState>
                </FirebaseDatabaseProvider>
            </MuiThemeProvider>
        );
    }
    if (loading) {
        return (
            <div className="centered paddingaround uniload">
                <Backdrop open="true" style={{zIndex:"69", backgroundColor:"#fff"}}>        
                    <Lottie animationData={uni} />
                    <h2
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Loading something special...
                    </h2>

                </Backdrop>
            </div>
        );
    }
    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
            </div>
        );
    }
    return (
        <div className="centered">
            <Login />
        </div>
    );
}
