import axios from "axios";

const authapi = axios.create({
  baseURL: "https://darkseagreencadetbluemathematics.ckellgren.repl.co",
  headers: {
    "Content-Type": "application/json; charset=UTF-8"
  },
  auth: {
    username: "test_user",
    password: "123456"
  }
});

export { authapi as default };
