import {
    SET_INITIAL_DATA,
    CREATE_ORDER,
    SET_ORDER_IN_PROGRESS,
    UPDATE_ORDER_PRODUCTS,
} from "../types.js";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
        case SET_INITIAL_DATA:
            console.log("SET_INITIAL");
            return { ...state, orders: action.payload };
        case CREATE_ORDER:
            return {
                ...state,
                orders: [...state.orders, action.payload],
                orderInProgress: action.payload,
            };
        case SET_ORDER_IN_PROGRESS:
            return {
                ...state,
                orderInProgress: action.payload,
            };
        case UPDATE_ORDER_PRODUCTS:
            console.log("REDUCER", state.orderInProgress);
            return {
                ...state,
                orderInProgress: {
                    ...state.orderInProgress,
                    products: action.payload,
                },
            };
        default:
            return { state };
    }
};
