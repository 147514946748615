//order details, payment, summary
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import api from "../../apis/api";
import Moment from "react-moment";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import { get, sumBy, toNumber } from "lodash";
import { Divider, ListItem, ListItemText, Avatar, Card, CardMedia } from "@material-ui/core";
import Truncate from "react-truncate";


export default function OrderSummary({ id }) {
    const [order, setOrder] = useState([]);
    const [recipient, setRecipient] = useState('');
    useEffect(() => {
        getOrders();
    }, [id]);

    const getOrders = async () => {
        // todo: current order in progress
        // const orderDetails = await api.get('/orders/' + JSON.stringify({cartId}));
        const orderDetails = await api.get(`/orders/${id}/`);
        setOrder(orderDetails.data);
        console.log(orderDetails.data);
    };
  
    const getRecipient = async () => {
        const recipientDetails = await api.get(`/recipients/${order.recipient}/`)
          setRecipient(recipientDetails.data);
        };
    getRecipient();
    return (
        <Grid container justify="space-around" spacing={3}>
            
        <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
           <h1 className="karla space">Order Summary</h1> 
           <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h2 className="pricing">
                    Wrapping & Packaging:</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>                
                <h1>
                    <span className="purple-text tiny-space">
                        {/* Value will come from Stripe (products x size + tax) */}
                        <b>&nbsp;
                            <NumberFormat
                                value={sumBy(order.products, (product) => toNumber(product.unit_price))}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale="2"
                                prefix="$"
                            />
                        </b>
                    </span>
                </h1>
                {/* Value will come from package size selection */}
                <p className="subtext">
                    Your total is calculated from your selections and your gift item's dimensions of {order.length} x {order.width} x {order.height} inches.
                </p>
                <Divider
                    light
                    variant="middle"
                    style={{ margin: "15px 0px", maxWidth: "500px" }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h2 className="pricing">
                    {/* Value will come from Shipstation */}
                    Shipping:{" "}
                </h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h1 className="purple-text tiny-space">
                    <b>FREE</b>
                </h1>
                <p className="subtext">
                    Shipping is always free around here.
                </p>
                <Divider
                    light
                    variant="middle"
                    style={{ margin: "15px 0px", maxWidth: "500px" }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h2 className="pricing">
                    {/* Value will come from Previous screen (use moment) and provided to ShipStation to calculate rates */}
                    Delivery Date:{" "}
                </h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h1 className="purple-text tiny-space">
                    <Moment  format="MMM Do" date={order.preferred_delivery_date} />
                </h1>
                <p className="subtext">
                    Your gift will be delivered via{" "}
                    <span className="subtext-blknomargin">
                        {/* Value will come from Shipstation */}
                        USPS Priority Mail Express®
                    </span>
                    {" "}on or before <Moment className="subtext-blknomargin" format="MMM Do" date={order.preferred_delivery_date} /> if we receive your items by <Moment className="subtext-blknomargin" format="MMM Do" subtract={{ days: 4 }} date={order.preferred_delivery_date} />
                </p>

            </Grid>
        </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Card style={{top:30, marginBottom:"40px"}}>
              <CardMedia style={{height:"80px", opacity:"0.85"}} component="img" src="https://i.ibb.co/1spCyB1/cellbkg.png"/>
               <div style={{padding:"0px 10px 10px 10px"}}>
                <h2 style={{position:"absolute",top:-55}} className="paddingaround">Order #{order.id || <Skeleton style={{top:-30}} />}</h2>
              
              <Grid item className="paddingaround space" lg={12}>
                <p className="subtext">Shipping to:</p>
                <p>{recipient.first_name} {recipient.last_name || <Skeleton />}</p>
                <p>{recipient.address_line_1 || <Skeleton />}</p>
                <p>{recipient.address_line_2}</p>
                <p>{recipient.city|| <Skeleton />} {recipient.state_province} {recipient.zip}</p>
                <Divider style={{margin:"10px 0px 10px 0px"}} />
                <p className="subtext">Gift item:</p>
                <ListItem dense>
                    <Avatar
                        variant="rounded"
                        style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "10px",
                        }}
                        alt={order.shortname || "?"}
                        src={order.image_url || "?"}
                    />

                    <ListItemText
                        primary={<Truncate lines={2}>{order.shortname}</Truncate>}
                    />  
                </ListItem>
            </Grid>
            <Divider style={{margin:"10px 0px"}} />
            <p className="subtext paddingaround">Gift wrap items:</p>
              {get(order, "products", []).map((product) => (
            <Grid item lg={12}>
                <ListItem dense>
                    <Avatar
                        variant="rounded"
                        style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "10px",
                        }}
                        alt={product.name}
                        src={product.image}
                    />

                    <ListItemText
                        primary={product.name}
                        secondary={
                            <NumberFormat
                                value={product.unit_price}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix="$"
                            />
                        }
                    />
                </ListItem>
            </Grid>
            ))}
            </div>
          </Card>
      </Grid>
        </Grid>
    );
}
