import authapi from "./socialauthapi";

const FoogleLogin = async (accesstoken) => {
  let res = await authapi.post("/dj-rest-auth/googlelogin/", {
    access_token: accesstoken,
    body: {
      username: "test_user",
      password: "123456"
    },
    password: "123456",
    username: "test_user"
  });
  console.log(res);
  return await res.status;
};

export default FoogleLogin;
