import React, { useContext } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Chip,
  withStyles,
  Button,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import Truncate from "react-truncate";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import InnerImageZoom from 'react-inner-image-zoom'
import ordersContext from "../../context/orders/ordersContext";
import { Link, useParams } from "react-router-dom";
const StyledCardContent = withStyles((theme) => ({
  root: {
    height: theme.spacing(11),
    paddingTop: "6px",
    paddingBottom: "5px",
    position: "relative",
  },
}))(CardContent);
const ProductsList = ({ data }) => {
  const { addProductToOrder, orderInProgress } = useContext(ordersContext);
  const { id } = useParams()
  console.log("🚀 ~ file: ProductsList.js ~ line 27 ~ ProductsList ~ id", data, id)
  return (
    <div className="">
      <Grid
        container
        flexDirection="row"
        style={{
          marginTop: "30px",
        }}
        spacing={2}
      >
        {data && data.length && data.length > 0 &&
          data.map((product) => (
            <Grid item xs={12} sm={4} md={3} lg={3} xl={3} key={product.id}>
              <Card
                className="mobilemax"
                key={product.id}
                style={{
                  backgroundColor: "white",
                  maxWidth: "250px",
                  marginBottom: "20px",
                }}
              >
                {/* <CardActionArea> */}
                <CardMedia style={{ height: "230px" }}><InnerImageZoom src={product.image} /></CardMedia>
                {/* <CardMedia
                    style={{ height: "230px" }}
                    component="img"
                    // src={<InnerImageZoom src={product.image}/>}
                    src={product.image}
                    alt={product.name}
                  /> */}

                <StyledCardContent>
                  <h4
                    className="micro-space"
                    color="primary"
                    style={{
                      marginBottom: "3px",
                    }}
                  >
                    <b><Truncate width={165}>{product.name}</Truncate></b>
                  </h4>
                  <p className="subtext-sm">
                    <Truncate lines={3}>{product.description}</Truncate>
                  </p>
                </StyledCardContent>
                {/* </CardActionArea> */}
                {orderInProgress && orderInProgress.status && (
                  <Link
                    to={`/products/${orderInProgress.id}`}
                    onClick={() => addProductToOrder(product)}
                  >
                    <Button
                      color="primary"
                      size="large"
                      fullWidth
                      className="btn"
                    >
                      Add for&nbsp;{" "}
                      <b>
                        <NumberFormat
                          value={product.unit_price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </b>
                    </Button>
                  </Link>
                )}
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default ProductsList;
