import React from "react";
import { 
  Paper, 
  Box, 
  ListItem, 
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";

const PromoBanner = () => {
  return (
    <div>
      
        <ListItem disableGutters>
          <img alt="Free laser engraved leather tag with every order promo banner"src="https://drive.google.com/uc?id=1y1X27jDUqYk4tCaiQnBWn4Drq-oCCOSe" style={{maxWidth:"100%",margin:"0 auto", borderRadius:"5px"}}/>
          {/* <ListItemAvatar>
          <span style={{fontSize:"26px"}} role="img">❤️</span>
          </ListItemAvatar>
          <ListItemText
          style={{color:"white"}}
          primary={<h3>We think you're perfect.</h3>}
          secondary={<p style={{color:"white",opacity:"0.7"}}>Take $10 off of your first order. Discount will be applied automatically at checkout.</p>}
          /> */}
          {/* <h2
            style={{
              color: "#faf5ed",
            }}>
            <span role="img">❤️</span> &nbsp;We think you're perfect.
          </h2>
          <br /><p
          style={{
            color: "#faf5ed",
            marginLeft:"10px",
            fontSize:".9em",
            opacity:"0.8",
          }}>
          Take $10 off of your first order. Discount will be applied automatically.
          </p> */}
          </ListItem>
    </div>
  );
};

export default PromoBanner;
