import moment from "moment";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../apis/api";
import ordersContext from "../context/orders/ordersContext";

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const {getInitialOrders} = useContext(ordersContext)
  const history = useHistory()
  const recipientInitialState = {
    first_name: "",
    last_name: "",
    address_line_1: "",
    address_line_2: "",
    state_province: "",
    zip: "",
  };
  const shippingInitialState = moment.utc().add(7, "days").toISOString();
  const [shipmentDetails, setShipmentDetails] = useState(recipientInitialState);
  const [preferredDeliveryDate, setPreferredDeliveryDate] =
    useState(shippingInitialState);
  

  const handleShipmentChange = (value, name) => {
    setShipmentDetails({ ...shipmentDetails, [name]: value });
  };

  const handleDeliveryDateChange = (date) => {
    setPreferredDeliveryDate(date);
  };

  const handleSubmit = async (id) => {
    await api
      .post(`/recipients/`, shipmentDetails)
      .then(async (res) => {
        console.log(res);
        await api.patch(`/orders/edit/${id}/`, {
          recipient: res.data.id,
          preferred_delivery_date: preferredDeliveryDate,
          status: "awaiting"
        });
      }).then(() => getInitialOrders()).then(() => history.push(`/confirmation/${id}`));
  };

  return [
    shipmentDetails,
    preferredDeliveryDate,
    handleShipmentChange,
    handleDeliveryDateChange,
    handleSubmit,
  ];
}
