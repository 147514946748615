import React, { useReducer } from "react";
import OrdersContext from "./ordersContext";
import OrdersReducer from "./ordersReducer";
import {
  SET_INITIAL_DATA,
  CREATE_ORDER,
  SET_ORDER_IN_PROGRESS,
  UPDATE_ORDER_PRODUCTS,
} from "../types.js";
import api from "../../apis/api";
import { find, get } from "lodash";
const OrdersState = (props) => {
  const initialState = {
    orders: [],
    orderInProgress: {},
  };
  const [state, dispatch] = useReducer(OrdersReducer, initialState);

  React.useEffect(() => {
    getInitialOrders();
  }, []);

  const getInitialOrders = async () => {
    await api.get("/orders/").then((response) => {
      const orders = get(response, "data", []);
      console.log(
        "🚀 ~ file: OrdersState.js ~ line 14 ~ api.get ~ orders",
        orders
      );
      dispatch({ type: SET_INITIAL_DATA, payload: orders });
    });
  };

  const createNewOrder = async (newOrder) => {
    const response = await api.post("/orders/", newOrder);
    dispatch({
      type: CREATE_ORDER,
      payload: response.data,
    });
  };

  const setOrderInProgress = (id) => {
    const selectedOrder = find(
      state.orders,
      (order) => `${order.id}` === `${id}`
    );

    dispatch({ type: SET_ORDER_IN_PROGRESS, payload: selectedOrder });
  };

  const addProductToOrder = async (product) => {
    const existingProducts = [...state.orderInProgress.products];
    const updatedProducts = [];
    if (
      find(
        existingProducts,
        (existingItem) => existingItem.category === product.category
      )
    ) {
      existingProducts
        .filter((p) => p.category !== product.category)
        .map((p) => updatedProducts.push(p));
      updatedProducts.push(product);
    } else {
      existingProducts.map((p) => updatedProducts.push(p));
      updatedProducts.push(product);
    }
    const response = await api.patch(`/orders/edit/${state.orderInProgress.id}/`, {
      products: updatedProducts.map((p) => p.id),
    });
    dispatch({
      type: UPDATE_ORDER_PRODUCTS,
      payload: updatedProducts,
    });
  };

  return (
    <OrdersContext.Provider
      value={{
        orders: state.orders,
        orderInProgress: state.orderInProgress,
        createNewOrder,
        setOrderInProgress,
        addProductToOrder,
        getInitialOrders
      }}
    >
      {props.children}
    </OrdersContext.Provider>
  );
};

export default OrdersState;
