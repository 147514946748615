import { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Container from "@material-ui/core/Container";
import { CookiesProvider } from "react-cookie";
import ProductsState from "./context/products/ProductsState";

const rootElement = document.getElementById("root");
ReactDOM.render(
    <StrictMode>
        <ProductsState>
            {/* <CookiesProvider> */}
            <div className="bkg">
                <Container maxWidth="lg">
                    <App />
                </Container>
            </div>
            {/* </CookiesProvider> */}
        </ProductsState>
    </StrictMode>,
    rootElement
);
