import React from "react";
import Lottie from "lottie-react";
import gift from "../animations/gift.json";
import GoogleSocialAuth from "../components/GoogleSocialAuth";
import logogift from "../animations/logogift.json";
import GoogleAuth from "../components/GoogleAuth";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
// import img from "../static/images/logo-dk.png";

const Login = () => (
  <Container className="loginpagenomargin" style={{ marginTop: "40px" }}>
    <div style={{ maxWidth: "700px", verticalAlign: "middle" }}>
      <h1 className="centered" style={{ fontWeight: "800" }}>
        <span
          role="img"
          aria-label="Present emoji"
          style={{ fontSize: ".9em" }}
        >
          🎁{" "}
        </span>
        Shipwrapped&nbsp;
        <Chip variant="outlined" color="primary" label="BETA" size="small" />
      </h1>
      {/* <img
      alt="Shipwrapped Logo"
      className="space"
      style={{ maxWidth: "270px" }}
      src={img}
    /> */}
      <h3
        style={{ fontWeight: 300, paddingLeft: "25px", paddingRight: "25px" }}
        className="centered"
      >
        "This is the biggest game changer for me. No more lousy gift sacks for
        me and my friends."
      </h3>
      <p className="centered" style={{ fontSize: ".95em", marginTop: "5px" }}>
        {" "}
        <i>Meg B., Former low-effort gifter</i>
      </p>

      <Lottie animationData={gift} />

      <GoogleAuth />
      <GoogleSocialAuth />
    </div>
  </Container>
);

export default Login;
