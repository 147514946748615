import React from "react";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import { startCase, sumBy, toInteger, toNumber } from "lodash";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Link as RouterLink } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Truncate from "react-truncate";
const OrderCard = ({ order, setOrderInProgress }) => {
    return (
        <Card
            key={order.id}
            style={{
                marginBottom: "15px",
            }}
            variant="outlined"
            className="cardmax"
        >
            <CardMedia
                style={{
                    height: 300,
                    maxWidth: "375px",
                }}
                className="media"
                component="img"
                src={order.image || order.image_url || "https://i.ibb.co/WPGFVcb/gift-placeholder.png"}
                title={order.shortname}
            />
            <CardContent style={{ marginLeft: "5px" }}>
                <div>
                    <p className="subtext floatleft">{"Order #" + order.id}</p>
                    <Chip
                        className="floatright"
                        variant="outlined"
                        label={startCase(order.status)}
                        size="medium"
                        icon={
                            <CheckCircleOutlineOutlinedIcon
                                style={{
                                    display:
                                        order.status === "delivered"
                                            ? ""
                                            : "none",
                                    color:
                                        order.status === "delivered"
                                            ? "green"
                                            : "",
                                }}
                            />
                        }
                    />
                    <br />
                    <h3 className="tiny-space"><Truncate lines={2}>{order.shortname}</Truncate></h3>
                    <p className="tiny-space">
                        {"Delivery date: " +
                            moment(order.preferred_delivery_date).format(
                                "MMMM Do YYYY"
                            )}
                    </p>
                </div>

                <h4 className="tiny-space">Selected items:</h4>
                <p className="subtext">
                    {order.products.map((product) => (
                        <li>
                            <span className="subtext-sm-blknomargin">
                                {product.name}
                            </span>
                            {` - `}
                            {
                                <NumberFormat
                                    value={product.unit_price}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix="$"
                                />
                            }
                        </li>
                    ))}
                </p>
                <p>
                    <NumberFormat
                        value={sumBy(order.products, (product) => toNumber(product.unit_price))}
                        displayType={"text"}
                        decimalScale="2"
                        thousandSeparator={true}
                        prefix="$"
                    /> charged to <b>Mastercard 2222</b>
                </p>
                {order.status === "pending_submit" ? (
                    <RouterLink
                        style={{
                            textDecoration: "none",
                            color: "black",
                        }}
                        to={`/products/${order.id}`}
                        onClick={() => setOrderInProgress(order.id)}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            className="space"
                            style={{
                                display:
                                    order.status === "delivered"
                                        ? "none"
                                        : "flex",
                            }}
                        >
                            Continue Customizing
                        </Button>
                    </RouterLink>
                ) : (
                    <Button
                        variant="contained"
                        size="small"
                        className="space"
                        style={{
                            display:
                                order.status === "delivered" ? "none" : "flex",
                        }}
                    >
                        Track this order
                    </Button>
                )}
            </CardContent>
            <Button size="small" className="center tiny-space">
                Questions about this order?
            </Button>
        </Card>
    );
};

export default OrderCard;
