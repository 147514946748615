import React from "react";
import Slider from "@material-ui/core/Slider";

const imageURLs = [
    "https://cdn.shopify.com/s/files/1/2618/8176/products/5-1-4-x-3-3-4-x-7-8-kraft-jewelry-boxes_900x.jpg?v=1525749466",
    "https://static.nashvillewraps.com/images/sku/GBL10GOM-XLARGE.jpg",
    "https://images.uline.com/is/image/content/dam/images/S/S4500/S-4307.jpg?id=WQTdH2&fmt=jpg&fit=constrain,1&wid=498&hei=374&iccEmbed=1&icc=AdobeRGB",
    "https://5.imimg.com/data5/SELLER/Default/2020/9/FZ/VP/IK/37495777/primo-brown-cube-box-3ply-12x12x10-inches-pack-of-25-pcs--250x250.jpg",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaC2TomiNU7HhqLyiltAaqQX01ZHuhG4BH5hLqsoo9w2RvrWUQmnn6Q92yYz0G-Upo4Pw&usqp=CAU",
];

const marks = [
    { value: 0, label: "XS" },
    { value: 1, label: "S" },
    { value: 2, label: "M" },
    { value: 3, label: "L" },
    { value: 4, label: "XL+" },
];

function labelTransformer(value) {
    // return "test";
    var a = marks.find((mark) => mark.value === value);
    if (typeof a == "object") return a.label; // console.log(a.label);
    return value;
}

export default function PackageSizeSlider({ onChange }) {
    const [value, setValue] = React.useState(1);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        onChange(newValue, "size");
    };

    return (
        <form
            className="tiny-space"
            style={{
                border: "1px solid #ebebeb",
                borderRadius: "5px",
                padding: "20px 30px",
            }}
        >
            <Slider
                className="tiny-space"
                value={value}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={1}
                min={0}
                max={4}
                onChange={handleChange}
                valueLabelFormat={labelTransformer}
                marks={marks}
            />
            <div style={{height:"180px"}}>
            <img
                alt="package size example"
                className="center"
                style={{ maxWidth: "200px" }}
                src={imageURLs[value]}
            />
            </div>
        </form>
    );
}
