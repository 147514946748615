import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faChrome, faSafari, faFirefox } from "@fortawesome/free-brands-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { Button, Grid, Chip, Card, CardMedia, ListItem, Avatar, ListItemText, Divider } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import NumberFormat from "react-number-format";
import Truncate from "react-truncate";
import { get, startCase } from "lodash";
import Lottie from "lottie-react";
import confetti from "../../animations/confetti.json";
import { useParams } from "react-router-dom";
import api from "../../apis/api";
import Skeleton from "react-loading-skeleton";
import { detect } from "detect-browser";

const browser = detect();

const ShippingAddress = () => {
  const { id } = useParams();
  console.log("ID", id);
  const [isCopied, setIsCopied] = useState(false);


  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const [order, setOrder] = useState({});
  const [recipient, setRecipient] = useState({});
  const [location, setLocation] = useState({});
  useEffect(() => {
    getOrders(id);
    console.log(id);
  }, [id]);

  const getOrders = async () => {
    const res = await api.get(`/orders/${id}/`).then(async (res) => {
      setOrder(res.data);
      await api
        .get(`/recipients/${res.data.recipient}/`)
        .then((res) => setRecipient(res.data));
      await api
        .get(`/locations/${res.data.location}/`)
        .then((res) => setLocation(res.data));
    });
  };


  var addressSnippet; 
    if(location.zip) {
     addressSnippet = `Shipwrapped G${order.id} \n
      ${location.address_line_1} \n
      ${location.city} ${location.state_province}, ${location.zip}`
    } else {
      addressSnippet = <Skeleton count={3} height={25} style={{width:"51%",marginBottom:"10px"}}/>
    };

return (  
    <Grid container justify="space-around" spacing={3} style={{top:10}}>
      <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <h1 className="sectionheader">Order Confirmed</h1>
        <Chip variant="outlined" label={<p>Status: {startCase(order.status || `...`)}</p>}/>
      </Grid>
      <div className="nomobile" style={{ maxWidth: "600px" }}>
      <h2 className="sectionheader">
            Browser Extension{" "}
            <FontAwesomeIcon
              className="purple-text fa-xs"
              icon={faChrome}
            />{" "}
            <FontAwesomeIcon
              className="purple-text fa-xs"
              icon={faSafari}
            />{" "}
            <FontAwesomeIcon
              className="purple-text fa-xs"
              icon={faFirefox}
            />{" "}
            &nbsp;
        </h2>
        <p className="mutetext">
          The <a href="#" className="purple-text">Shipwrapped Browser Extension</a> is the easiest way to enter your Shipwrapped address.
        </p>
        <Button variant="outlined" className="space">Add to {browser.name}</Button>
      </div>
      <div>
        <h2 className="sectionheader">
            {" "}
            Your Shipwrapped Address{" "}
            <FontAwesomeIcon
              className="purple-text fa-xs"
              icon={faQuestionCircle}
            />{" "}
            &nbsp;
        </h2>
        <p className=" mutetext">
          Ensure all gift items are shipped to this address. Anything shipped
          here will be wrapped and sent on to your specified Gift Recipient.
        </p>
        <div>
          <h2 className="" style={{ color: "#858585" }}>
            <div className="space copypasta">{addressSnippet}</div>
          </h2>
        </div>
        <CopyToClipboard text={addressSnippet} onCopy={onCopyText}>      
          <span>
            {isCopied ? (
              <div className="">
                <Button
                  style={{
                    marginBottom: "5px",
                  }}
                  variant="contained"
                  size="large"
                  fullWidth
                  className="tiny-space alignitemleft"
                >
                  <FontAwesomeIcon icon={faCopy} />
                  &nbsp; ADDRESS COPIED!
                </Button>
                <Lottie
                  animationData={confetti}
                  loop="false"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    top: 300,
                    position: "fixed",
                    zIndex:"420"
                  }}
                />
              </div>
            ) : (
              <div style={{ justifyContent: "center" }}>
                <Button
                  style={{
                    marginBottom: "5px",
                  }}
                  variant="contained"
                  size="large"
                  className="tiny-space alignitemleft"
                  fullWidth
                >
                  <FontAwesomeIcon icon={faCopy} />
                  &nbsp; Copy Shipping Address
                </Button>
              </div>
            )}
          </span>
        </CopyToClipboard>
      </div>
      </Grid>
  
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Card style={{top:20}}>
              <CardMedia style={{height:"80px", opacity:"0.85"}} component="img" src="https://i.ibb.co/1spCyB1/cellbkg.png"/>
               <div style={{padding:"0px 10px 10px 10px"}}>
                <h2 style={{position:"absolute",top:-55}} className="paddingaround">Order #{order.id || <Skeleton style={{top:-30}} />}</h2>
              
              <Grid item className="paddingaround space" lg={12}>
                <p className="subtext">Shipping to:</p>
                <p>{recipient.first_name} {recipient.last_name || <Skeleton />}</p>
                <p>{recipient.address_line_1 || <Skeleton />}</p>
                <p>{recipient.address_line_2}</p>
                <p>{recipient.city|| <Skeleton />} {recipient.state_province} {recipient.zip}</p>
                <Divider style={{margin:"10px 0px 10px 0px"}} />
                <p className="subtext">Gift item:</p>
                <ListItem dense>
                    <Avatar
                        variant="rounded"
                        style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "10px",
                        }}
                        alt={order.shortname || "?"}
                        src={order.image_url || "?"}
                    />

                    <ListItemText
                        primary={<Truncate lines={2}>{order.shortname}</Truncate>}
                    />
                </ListItem>
            </Grid>
            <Divider style={{margin:"10px 0px"}} />
            <p className="subtext paddingaround">Gift wrap items:</p>
              {get(order, "products", []).map((product) => (
            <Grid item lg={12}>
                <ListItem dense>
                    <Avatar
                        variant="rounded"
                        style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "10px",
                        }}
                        alt={product.name}
                        src={product.image}
                    />

                    <ListItemText
                        primary={product.name}
                        secondary={
                            <NumberFormat
                                value={product.unit_price}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix="$"
                            />
                        }
                    />
                </ListItem>
            </Grid>
            ))}
            </div>
          </Card>
      </Grid>
    </Grid>
  );
};

export default ShippingAddress;
