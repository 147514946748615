import React, { useContext } from "react";
import {
    Box,
    List,
} from "@material-ui/core";
import { useParams } from "react-router-dom";


import ordersContext from "../../context/orders/ordersContext";
import ProductsLink from "./ProductsLink";

const ProductsDashboard = () => {
    const { id } = useParams();
    const { setOrderInProgress, orders } = useContext(ordersContext);

    React.useEffect(() => { if (orders) { setOrderInProgress(id) } }, [id, orders])
    return (
        <div>
            <Box minWidth={240}>
                <List>
                    <ProductsLink subroute={"wrap"} />
                    <ProductsLink subroute={"ribbon"} />
                    <ProductsLink subroute={"bow"} />
                    <ProductsLink subroute={"extra"} />
                    <ProductsLink subroute={"card"} />
                </List>
            </Box>
        </div>

    );
};

export default ProductsDashboard;