import React, { useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import {
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
} from "@material-ui/core";

import SelectedAvatar, {
    NotSelectedAvatar,
} from "../../avatars/SelectionAvatars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import ordersContext from "../../context/orders/ordersContext";
import { get } from "lodash";

const ProductsLink = ({ subroute }) => {
    const { url } = useRouteMatch();
    const { orderInProgress } = useContext(ordersContext);
    return (
        <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`${url}/${subroute}`}
        >
            <ListItem
                button
                style={{
                    padding: "15px 20px 15px 10px",
                    marginBottom: "10px",
                    border: "1px solid #ebebeb",
                    borderRadius: "10px",
                }}
            >
                {get(orderInProgress, "products", []).find(
                    (o) => o.category === subroute
                ) ? (
                    <SelectedAvatar
                        image={
                            get(orderInProgress, 'products', []).find((o) => o.category === subroute)
                                .image
                        }
                    />
                ) : (
                    <NotSelectedAvatar />
                )}
                <ListItemText>
                    <h3>{subroute}s</h3>
                    <p style={{ color: "gray" }}>
                        {get(orderInProgress, "products", []).find(
                            (o) => o.category === subroute
                        )
                            ? orderInProgress.products.find((o) => o.category === subroute)
                                .name
                            : "None selected"}
                    </p>
                </ListItemText>
                <ListItemSecondaryAction>
                    <IconButton className="nomobile">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </Link>
    );
};

export default ProductsLink