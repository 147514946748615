import React from "react";
import GoogleLogin from "react-google-login";
import FoogleLogin from "../apis/GoogleLoginService";

const GoogleSocialAuth = () => {
  const responseGoogle = async (response) => {
    let responseGoogle = await FoogleLogin(response.accessToken);
    console.log("RESPONSE GOOGLE", responseGoogle);
    console.log("RESPONSE", response);
  };

  const responseFAILGoogle = async (response) => {
    let responseGoogle = await FoogleLogin(response.accessToken);
    console.log("RESPONSE FAIL GOOGLE", responseGoogle);
    console.log("RESPONSE FAIL", response);
  };

  return (
    <div>
      <h1>LOGIN WITH GOOGLE</h1>

      <GoogleLogin
        clientId="1034959369963-su5vgil3j0p87r68unce8kpulmkdcrit.apps.googleusercontent.com"
        buttonText="LOGIN WITH GOOGLE"
        onSuccess={responseGoogle}
        onFailure={responseFAILGoogle}
        cookiePolicy={"single_host_origin"}
      />
    </div>
  );
};

export default GoogleSocialAuth;
