import React, { useContext, useState } from "react";
import Stripe from "../components/Stripe";
import { List, ListItem, Divider, Button, Grid, Avatar } from "@material-ui/core";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import SettingsModal from "../components/SettingsModal";
import GiftModal from "../components/GiftModal";
import PromoBanner from "../components/PromoBanner";
import ordersContext from "../context/orders/ordersContext";
import Skeleton from "react-loading-skeleton";
import OrderList from "../components/Orders/OrderList";
import { filter } from "lodash";
const Home = () => {
    const [user] = useAuthState(firebase.auth());
    const { orders } = useContext(ordersContext);

    const [isOpen, setOpen] = useState(false);
      function toggleOpen() {
        setOpen(!isOpen);
    }

    return (
        <div>
            <PromoBanner />
            {/* <h1 className="howdy">
                <a>Howdy, {user.displayName.split(" ")[0]}</a>

            </h1> */}
            <h2>
                <GiftModal />
                <Button 
                variant="outlined"
                onClick={setOpen}
                >
                <FontAwesomeIcon icon={faAmazon} />&nbsp;&nbsp;Find a gift on Amazon
                </Button>
            </h2>

            <Modal
                onRequestClose={toggleOpen}
                isOpen={isOpen}
                contentLabel="Find a gift on Amazon.com"
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    zIndex: '99999',
                    },
                    content: {
                    position: 'absolute',
                    top: '25px',
                    left: '30px',
                    right: '30px',
                    bottom: '20px',
                    background: '#fff',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '5px',
                    outline: 'none',
                    padding: '10px 20px 0px 20px',
                    maxWidth: '500px',
                    maxHeight: '360px',
                    margin: 'auto',
                    }
                }}
            >
                <h2 className="sectionheader" style={{fontWeight:800}}>Find a gift on Amazon</h2>
                <Grid container direction="row" justify="flex-start" spacing={2} style={{marginTop:"10px"}}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                        <ListItem target="_blank" button component="a" href="https://amzn.to/2Vk0isi" >
                            <Avatar src="https://m.media-amazon.com/images/I/716aK-sZejL._AC_SL1500_.jpg" variant="square" size="large"/><h4 style={{marginLeft:"10px"}}>Newborn</h4>
                        </ListItem>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                        <ListItem target="_blank" button component="a" href="https://www.amazon.com/gp/search?ie=UTF8&tag=shipwrapped-20&linkCode=ur2&linkId=9c72b2293bc32d976d4681fa9154d475&camp=1789&creative=9325&index=hpc&keywords=wedding gift">
                            <Avatar src="https://media-api.xogrp.com/images/11e12f14-b5bc-40a6-af5e-34c4a15a3548~rs_768.h" variant="square" size="large"/><h4 style={{marginLeft:"10px"}}>Wedding</h4>
                        </ListItem>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                    <ListItem target="_blank" button component="a" href="https://www.amazon.com/b?_encoding=UTF8&tag=shipwrapped-20&linkCode=ur2&linkId=029b19dcbb18f81fca4adfdc388c767b&camp=1789&creative=9325&node=1063278">
                            <Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMt77g54XmISwXDuG4uR4uHdm1Z34XsYcW7iU8sjKinCPSauQjvwuSc6l3tBeu2t8L2CWEFDmp&usqp=CAc" variant="square" size="large"/><h4 style={{marginLeft:"10px"}}>Housewarming</h4>
                        </ListItem>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                        <ListItem target="_blank" button component="a" href="https://amzn.to/3rZyh5i">
                            <Avatar src="https://www.icanteachmychild.com/wp-content/uploads/2015/11/Melissa-Doug-Pound-a-Peg-650x474.jpg" variant="square" size="large"/><h4 style={{marginLeft:"10px"}}>Toddler</h4>
                        </ListItem>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                        <ListItem target="_blank" button component="a" href="https://amzn.to/2Vdg1JO">
                            <Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_g8BUI5QWBticTVqDzFiLOOaj8GT0-s13ng&usqp=CAU" variant="square" size="large"/><h4 style={{marginLeft:"10px"}}>Kids</h4>
                        </ListItem>
                    </Grid> 
                    {/* <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                        <ListItem target="_blank" button component="a" href="https://amzn.to/3CbXXQI">
                            <Avatar src="https://storcpdkenticomedia.blob.core.windows.net/media/recipemanagementsystem/media/recipe-media-files/recipes/retail/x17/16714-birthday-cake-600x600.jpg?ext=.jpg" variant="square" size="large"/><h4 style={{marginLeft:"10px"}}>Birthday</h4>
                        </ListItem>
                    </Grid>  */}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                        <ListItem target="_blank" button component="a" href="https://amzn.to/3lsmpHv">
                            <Avatar src="https://www.ipromo.com/blog/wp-content/uploads/2019/09/secrets-to-corporate-gifting.jpg" variant="square" size="large"/><h4 style={{marginLeft:"10px"}}>Interesting finds</h4>
                        </ListItem>
                    </Grid> 
                </Grid>
                {/* <p className="subtext-sm" style={{marginTop:"20px", fontSize:"10px"}}><i>We may earn a small commission when you buy something using the Amazon links on this page.</i></p> */}

          </Modal>
            <Divider
                light
                variant="middle"
                style={{ marginTop: "20px", maxWidth: "500px" }}
            />
            <OrderList
                data={filter(orders, (o) => o.status === "pending_submit")}
                title="Pending Orders"
            />
            <OrderList
                data={filter(orders, (o) => o.status === "awaiting")}
                title="Awaiting Items"
            />
            <OrderList
                data={filter(orders, (o) => o.status === "delivered")}
                title="Completed Orders"
            />
            <Divider
                light
                variant="middle"
                style={{ maxWidth: "500px", marginTop: "10px" }}
            />
        </div>
    );
};
export default Home;
