import { find } from "lodash";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import ordersContext from "../../context/orders/ordersContext";
import Skeleton from "react-loading-skeleton";
import OrderCard from "./OrderCard";

const OrderDetail = () => {
    const { id } = useParams();
    const { orders, setOrderInProgress } = useContext(ordersContext);
    const [order, setOrder] = useState(null);

    React.useEffect(() => {
        if (orders) {
            setOrder(find(orders, (o) => `${o.id}` === `${id}`));
        }
    }, [id, orders]);

    if (order) {
        return (
            <div>
                <h1 className="sectionheader">{"Order #" + order.id}</h1>
                <div style={{ display: "flex" }}>
                    <OrderCard
                        order={order}
                        setOrderInProgress={setOrderInProgress}
                    />
                </div>
            </div>
        );
    } else {
        return <div style={{marginTop:"50px", width:"350px"}}><Skeleton count={6} style={{height:"20px", margin:"15px 0px"}}/></div>;
    }
};

export default OrderDetail;
