import React, { useState, Fragment, useContext } from "react";
import Modal from "react-modal";
import "react-spring-modal/styles.css";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import PackageSizeSlider from "./PackageSizeSlider";
import { Link as RouterLink } from "react-router-dom";
import { find, get } from "lodash";
import Select from "react-select";
import ordersContext from "../context/orders/ordersContext";
function GiftModal() {
    const { createNewOrder } = useContext(ordersContext);
    const initialState = {
        products: [],
        status: "pending_submit",
        shortname: "",
        number_of_items: null,
        size: null,
        user: 1,
    };
    const NUMBER_OF_ITEMS_OPTIONS = [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
    ];
    const [isOpen, setOpen] = useState(false);
    const [orderInformation, setOrderInformation] = useState(initialState);
    const handleOnChange = (value, name) => {
        setOrderInformation({
            ...orderInformation,
            [name]: value,
        });
    };

    if (isOpen) {
        document.body.style.overflow = 'hidden';
        } else {
        document.body.style.overflow = 'unset';
        }

    function toggleOpen() {
        console.log("Toggling to:", !isOpen);
        setOpen(!isOpen);
    }
    return (
        <Fragment>
            <Button
                onClick={toggleOpen}
                className="space"
                variant="contained"
                size="large"
                style={{marginRight:"15px"}}
            >
                <h4>
                    <FontAwesomeIcon icon={faPlus} /> &nbsp;Get your own items wrapped 
                </h4>
            </Button>

            <Modal
            appElement={document.getElementById('App')}
            isOpen={isOpen}
            onRequestClose={toggleOpen}
            contentLabel="Create a new gift"
            style={{
                overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                zIndex: '99999',
                },
                content: {
                position: 'absolute',
                top: '25px',
                left: '30px',
                right: '30px',
                bottom: '20px',
                background: '#fff',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '5px',
                outline: 'none',
                padding: '0px 40px 0px 40px',
                maxWidth: '500px',
                maxHeight: '620px',
                margin: 'auto',
                }
            }}
        >
                <h2 className="space">Tell us about your gift</h2>
                <p>Give it a name</p>
                <TextField
                    inputProps={{ style: { fontFamily: "karla" } }}
                    required
                    name={"shortname"}
                    id="filled-required"
                    label={<span>Name</span>}
                    variant="outlined"
                    className="tiny-space"
                    onChange={(e) =>
                        handleOnChange(e.target.value, e.target.name)
                    }
                    value={orderInformation["shortname"]}
                    defaultValue={1}
                    style={{ marginTop: "10px" }}
                    size="small"
                    fullWidth
                />
                <p>How many items will your gift include?</p>
                <Select
                    className="tiny-space karla"
                    isSearchable={false}
                    options={NUMBER_OF_ITEMS_OPTIONS}
                    value={find(
                        NUMBER_OF_ITEMS_OPTIONS,
                        (option) =>
                            option.value ===
                            get(orderInformation, "number_of_items")
                    )}
                    onChange={(selectedOption) =>
                        handleOnChange(selectedOption.value, "number_of_items")
                    }
                />
                <p>Approximate Package Size</p>
                <PackageSizeSlider onChange={handleOnChange} />
                <Button
                    component={RouterLink}
                    to={"/products"}
                    onClick={() => createNewOrder(orderInformation)}
                    fullWidth
                    variant="contained"
                    size="large"
                    className="space"
                >
                    <p>Get Started</p>
                </Button>

      </Modal>
        </Fragment>
    );
}

export default GiftModal;
