import React from "react";
import OrderSummary from "./OrderSummary";
import ShippingAddress from "./ShippingAddress";
import DeliveryDetails from "./DeliveryDetails";
import {
  Stepper,
  Grid,
  Step,
  StepLabel,
  Button,
  AppBar,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import useDeliveryDetails from "../../utils/useDeliveryDetails";

export default function CheckoutDashboard() {
  const history = useHistory();
  const { id } = useParams();
  const [
    shipmentDetails,
    preferredDeliveryDate,
    handleShipmentChange,
    handleDeliveryDateChange,
    handleSubmit,
  ] = useDeliveryDetails();
  const [activeStep, setActiveStep] = React.useState(0);
  function getSteps() {
    return ["Delivery Details", "Order Summary"];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <DeliveryDetails
            shipmentDetails={shipmentDetails}
            preferredDeliveryDate={preferredDeliveryDate}
            handleShipmentChange={handleShipmentChange}
            handleDeliveryDateChange={handleDeliveryDateChange}
          />
        );
      case 1:
        return id ? <OrderSummary id={id} /> : <p>Loader</p>;
      default:
        return "Unknown stepIndex";
    }
  }
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>
        {activeStep === steps.length ? (
          <div>
            <ShippingAddress />
            {/* <Button onClick={handleReset}>Reset</Button> */}
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
            <div
              style={{
                paddingBottom: "15px",
                backgroundColor: "#fffff",
                width: "100%",
              }}
            >
              <div style={{ height: "80px" }} />
              <AppBar
                color="white"
                style={{ top: "auto", bottom: "0" }}
                elevation={5}
              >
                <Grid
                  container
                  className="appbarsize"
                  style={{ padding: "16px 0px" }}
                  justify="flex-end"
                >
                  <Grid item xs={10} sm={7} md={5} lg={5} xl={5}>
                    <Button
                      color="secondary"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      size="large"
                    >
                      Back
                    </Button>

                    <Button
                      variant="contained"
                      size="large"
                      onClick={
                        activeStep === steps.length - 1
                          ? () => {
                              handleSubmit(id);
                            }
                          : handleNext
                      }
                      style={{ width: "180px", marginLeft: "20px" }}
                    >
                      {activeStep === steps.length - 1
                        ? "Complete Order"
                        : "Review Order"}
                    </Button>
                  </Grid>
                </Grid>
              </AppBar>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
