import React, { useContext } from "react";
import {
  Breadcrumbs,
  Button,
  Grid,
  ListItem,
  ListItemText,
  AppBar,
  Avatar
} from "@material-ui/core";
import { Route, Link as RouterLink, useRouteMatch, useParams } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import BallotIcon from "@material-ui/icons/Ballot";
import ProductsList from "./ProductsList";
import productsContext from "../../context/products/productsContext";
import ordersContext from "../../context/orders/ordersContext";
import { get, sumBy, toNumber } from "lodash";
import Truncate from "react-truncate";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import GiftModal from "../GiftModal";
import ProductsDashboard from "./ProductsDashboard";



const ProductsSubroutes = ({ wrap, ribbon, bow, extra, card }) => {
  return (
    <>
      <Route exact path="/products/">
        <ProductsDashboard />
      </Route>
      <Route exact path="/products/:id">
        <ProductsDashboard />
      </Route>
      <Route path="/products/:id/wrap">
        <ProductsList data={wrap} />
      </Route>
      <Route path="/products/:id/ribbon">
        <ProductsList data={ribbon} />
      </Route>
      <Route path="/products/:id/bow">
        <ProductsList data={bow} />
      </Route>
      <Route path="/products/:id/extra">
        <ProductsList data={extra} />
      </Route>
      <Route path="/products/:id/card">
        <ProductsList data={card} />
      </Route>
    </>
  );
};

const ProductsMain = () => {
  const { products } = useContext(productsContext);
  const { orderInProgress } = useContext(ordersContext);
  const { id } = get(orderInProgress, "id", "");

  return (
    products ? <div>
      <Breadcrumbs>
        <RouterLink style={{ textDecoration: "none", color: "black" }} to={"/"}>
          <p className="mutertext">
            <HomeIcon style={{ fontSize: 14, top: 2 }} />
            &nbsp;Home
          </p>
        </RouterLink>
        <RouterLink
          style={{ textDecoration: "none", color: "black" }}
          to={"/products"}
        >
          <p className="mutertext">
            <BallotIcon style={{ fontSize: 14, top: 2 }} />
            &nbsp;Create a Gift
          </p>
        </RouterLink>
      </Breadcrumbs>
      <Grid container className="sectionheader" justify="flex-start" direction="row" style={{marginBottom:"10px"}}>
        <Grid item className="floatleft" xs={3} sm={2} md={2} lg={1} xl={1}>
            <Avatar
                src={orderInProgress ? (orderInProgress.image_url ? orderInProgress.image_url : orderInProgress.image) : (orderInProgress ? orderInProgress.image : "https://i.ibb.co/WPGFVcb/gift-placeholder.png")}
                variant="rounded"
                style={{height:"60px", width:"60px",top:8}}
                />
        </Grid>
        <Grid item className="floatright" xs={9} sm={10} md={10} lg={11} xl={11}>
            <h1
                key={orderInProgress ? orderInProgress.shortname : ""}
            >
                <Truncate lines={2}>
                {get(orderInProgress, "status")
                    ? `Customize ${get(orderInProgress, "shortname", "")}`
                    : "Create a Gift"}
                </Truncate>
            </h1>
        </Grid>
      </Grid>


      <div className="flex">
        {!get(orderInProgress, "status") && <GiftModal />}
      </div>

      {products ? (
        <ProductsSubroutes
          wrap={products.wrap}
          ribbon={products.ribbon}
          bow={products.bow}
          extra={products.extra}
          card={products.card}
        />
      ) : (
        <div>
        <Skeleton height="50px" width="100" style={{marginBottom:"30px"}}/>
        <Skeleton count={10} width="500" style={{marginBottom:"10px", height:"80px"}}/>
        </div>
      )}
      <div style={{ paddingBottom: "110px" }}></div>
      <AppBar
        color="inherit"
        style={{ top: "auto", bottom: "0" }}
        elevation={5}
      >
        <Grid
          container
          className="appbarsize"
        >
          <Grid item xs={6} sm={7} md={8} lg={8} xl={8}>
            <ListItem dense>
              <ListItemText
                style={{ marginLeft: "10px" }}
                primary={<h3>Order Total</h3>}
                secondary={
                  <h2>
                    <NumberFormat
                      value={orderInProgress ? sumBy(orderInProgress.products, (product) => toNumber(product.unit_price)) : "$0.00"}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale="2"
                      prefix="$"
                    />
                  </h2>
                }
              />
            </ListItem>
          </Grid>
          <Grid 
          item xs={6} sm={5} md={4} lg={4} xl={4}
          style={{
            margin: "15px 0px",
            }}>
            <RouterLink
              to={{
                pathname: `/checkout/${get(orderInProgress, 'id')}`,
                state: { cartId: get(orderInProgress, 'id')},
              }}>
              <Button
                variant="contained"
                size="large"
                disabled={!get(orderInProgress, 'products', []).find((product) => product.category === "wrap")}>
                Proceed to checkout
              </Button>
            </RouterLink>
          </Grid>
        </Grid>
      </AppBar>
    </div > : 
    <div style={{justifyContent:"flex-start"}}>
        <Skeleton height="70px" width="100" style={{marginBottom:"30px"}}/>
        <Skeleton count={6} height="1000" width="500" style={{marginBottom:"10px", minHeight:"80px"}}/>
    </div>
  );
};

export default ProductsMain;
