import firebase from "firebase";
import Button from "@material-ui/core/Button";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

firebase.initializeApp({
  apiKey: "AIzaSyDQnVZGU-w1pEc-sFbGCp1DMS60Q738Whk",
  authDomain: "giftco-61a24.firebaseapp.com",
  projectId: "giftco-61a24",
  storageBucket: "giftco-61a24.appspot.com",
  messagingSenderId: "621696957868",
  appId: "1:621696957868:web:96e71bc8bf30c912e48e09"
});

//Needed for auth functions:
const auth = firebase.auth();

function SignInWithGoogle() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };
  return (
    <div>
      <Button
        className="space"
        variant="contained"
        size="large"
        style={{ fontWeight: "600" }}
        color="primary"
        onClick={signInWithGoogle}
      >
        <FontAwesomeIcon icon={faGoogle} /> &nbsp; Sign in with Google
      </Button>
    </div>
  );
}

// function SignOut() {
//   return (
//     <Button
//       onClick={() => {
//         auth.signOut();
//       }}
//     >
//       Sign out
//     </Button>
//   );
// }

// export const DashBoard = () => {
//   const [user] = useAuthState(auth);
//   console.log(user);

//   return user ? (
//     <div style={{ border: "solid black" }}>
//       User Dashboard <br></br>
//       Name: {user.displayName} <br></br>
//       Email: {user.email} <br></br>
//       Photo: <img src={user.photoURL} alt="profile pic" /> <br></br>
//     </div>
//   ) : (
//     <></>
//   );
// };

export default function GoogleAuth() {
  return (
    <div>
      <SignInWithGoogle />
      <div
        style={{ display: "flex", flexFlow: "row", justifyContent: "center" }}
      ></div>
    </div>
  );
}

export { auth };
