export const FORM_FIELDS = [
  {
    label: "Recipient's First Name",
    name: "first_name",
    xl: 6,
    lg: 6,
    md: 6,
    sm: 6,
    xs: 12,
  },
  {
    label: "Recipient's Last Name",
    name: "last_name",
    xl: 6,
    lg: 6,
    md: 6,
    sm: 6,
    xs: 12,
  },
  { label: "Address Line 1", name: "address_line_1", lg: 12, xs: 12 },
  { label: "Address Line 2", name: "address_line_2", lg: 12, xs: 12 },
  { label: "City", name: "city", lg: 6, xs: 6, s: 6 },
  { label: "State", name: "state_province", lg: 6, xs: 6, s: 6, md: 6 },
  { label: "Zipcode", name: "zip", lg: 12, xs: 12, s: 12, md: 12 },
];
