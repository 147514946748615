import { SET_INITIAL_DATA } from "../types.js";

export default (state, action) => {
    switch (action.type) {
        case SET_INITIAL_DATA:
            return { ...state, products: action.payload };
        default:
            return state;
    }
};
