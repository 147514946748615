import React, { useMemo } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import useResponsiveFontSize from "../useResponsiveFontSize";
import Button from "@material-ui/core/Button";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "1.05em",
          color: "#424770",
          letterSpacing: "0.05em",
          fontFamily: "Karla, sans-serif",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const CardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)
    });

    console.log("[PaymentMethod]", payload);
  };

  return (
    <div className="paycell" style={{ maxWidth: "600px" }}>
      <form className="pay" onSubmit={handleSubmit}>
        <label>
          <p className="subtext">
            A payment method is required to receive your Shipwrapped address, it
            will only be charged once your order is finalized and in route to
            the final destination.
          </p>
          <div className="space">
            <CardElement
              options={options}
              onReady={() => {
                console.log("CardElement [ready]");
              }}
              onChange={(event) => {
                console.log("CardElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardElement [blur]");
              }}
              onFocus={() => {
                console.log("CardElement [focus]");
              }}
            />
          </div>
        </label>
        <Button
          variant="contained"
          size="medium"
          type="submit"
          disabled={!stripe}
        >
          <p>Save Card</p>
        </Button>
      </form>
    </div>
  );
};

export default CardForm;
