import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import { startCase } from "lodash";
import { Link as RouterLink } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Truncate from "react-truncate";

const OrderList = ({ data, title }) => {
    const navigate  = (order) => {
        if(order.status === "pending_submit"){
            return `/products/${order.id}`
        }else if(order.status === "awaiting"){
            return `/confirmation/${order.id}`
        }else{
            return `/orders/${order.id}`
        }
    }
    if (data) {
        return (
            <div>
                <h1 className="sectionheader">
                    {title}
                    {""}
                </h1>
                <List aria-label="your gifts">
                    {data.map((order) => (
                        <RouterLink
                            style={{ textDecoration: "none", color: "black" }}
                            to={() => navigate(order)}
                        >
                            <ListItem
                                style={{
                                    padding: "15px 20px 15px 10px",
                                    marginBottom: "10px",
                                    border: "1px solid #ebebeb",
                                    borderRadius: "10px",
                                }}
                                key={order.id}
                                button
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        variant="rounded"
                                        style={{
                                            width: "60px",
                                            height: "60px",
                                            marginRight: "15px",
                                            marginLeft: "5px",
                                        }}
                                        alt={order.shortname}
                                        src={order.image || order.image_url || "https://i.ibb.co/rQH1Fjd/avatarplaceholder.png"}
                                    />
                                    {""}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                            <h3><Truncate lines={2}>{order.shortname}</Truncate></h3>
                                    }
                                    secondary={<p>{"Order #" + order.id}</p>}
                                    style={{paddingRight:"10%"}}
                                />
                                <Chip
                                    variant="outlined"
                                    label={startCase(order.status)}
                                    size="medium"
                                    icon={
                                        <CheckCircleOutlineOutlinedIcon
                                            style={{
                                                display:
                                                    order.status === "delivered"
                                                        ? ""
                                                        : "none",
                                                color:
                                                    order.status === "delivered"
                                                        ? "green"
                                                        : "",
                                            }}
                                        />
                                    }
                                />
                            </ListItem>
                        </RouterLink>
                    ))}
                </List>
            </div>
        );
    }
    return (
        <div>
            <Skeleton count={2} height={500} />
        </div>
    );
};

export default OrderList;
