import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import SettingsModal from "./SettingsModal";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import firebase from "firebase";
import { Link as RouterLink, Switch } from "react-router-dom";
import { auth } from "./GoogleAuth";
import HomeIcon from "@material-ui/icons/Home";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import Truncate from "react-truncate";
import ChatIcon from "@material-ui/icons/Chat";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";

function signOut() {
  auth.signOut();
}

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "fixed",
    height: "70px",
    bottom: 0,
    left: 10,
    justifyContent: "center",
    zIndex: "1"
  }
}));

const NavbarMobile = () => {
  const [isOpen] = useState(false);
  const [user] = useAuthState(firebase.auth());
  const matches = useMediaQuery("(max-width:768px)");
  const doesntmatches = useMediaQuery("(min-width:768px)");
  const [page, setPage] = React.useState(0);
  const classes = useStyle();

  return (
    <div>
      <span hidden={matches}>
        <Drawer
          variant="permanent"
          anchor="left"
          PaperProps={{
            style: {
              width: "220px",
              flexShrink: 0,
              backgroundColor: "#fff",
              // backgroundImage: `url(${"https://i.ibb.co/TqRZpD2/beige-paper.png"})`,
              backgroundRepeat: "repeat",
              paddingBottom: "80px"
            },
            className: "karla"
          }}
        >
          <div style={{ paddingTop: "40px", paddingLeft: "25px" }}>
            <RouterLink to="/"> 
              <img alt="Shipwrapped logo" style={{width:"160px"}}src="https://drive.google.com/uc?id=1Fhc7oy52tJxEgdLzKM4_2sjdI5VYcSPm"/>
            </RouterLink>
          {/* <img alt="Shipwrapped logo" style={{width:"150px",top:5}}src="https://lh3.google.com/u/7/d/1L9HjJ7bgtujRwYeQWqp_zVXsio1dls8y=w2880-h1442-iv2"/> */}
            {/* <h3 style={{ color: "black" }} className="navbar-item">
              <span
                role="img"
                aria-label="Present icon"
                style={{ top: 8, fontSize: "22px" }}
              >
                🎁&nbsp;
              </span><span className="logofont">
              Shipwrapped</span>
            </h3> */}
            {/* <p className="subtext-sm" style={{ paddingLeft: "25px" }}>
              Give better gifts.
            </p> */}
          </div>
          <Divider variant="middle" light style={{ marginTop: "30px" }} />
          <div className={`navbar-menu ${isOpen && "is-active"}`}>
            <List style={{ marginTop: "30px" }}>
              <ListItem button disableRipple key={"Home"}>
                <NavLink
                  className="navbar-item"
                  activeClassName="is-active"
                  exact={true}
                  to="/"
                >
                  <ListItemText
                    style={{ paddingLeft: "20px" }}
                    primary={
                      <h3 style={{ display: "flex" }}>
                        <HomeIcon
                          style={{
                            maxWidth: "20px",
                            margin: "auto 0",
                            left: -8
                          }}
                        />{" "}
                        &nbsp;&nbsp;{"Home"}
                      </h3>
                    }
                  />
                </NavLink>
              </ListItem>

              <ListItem disableRipple button key={"Orders"}>
                <NavLink
                  className="navbar-item"
                  activeClassName="is-active"
                  to="/orders"
                >
                  <ListItemText
                    style={{ paddingLeft: "20px" }}
                    primary={
                      <h3 style={{ display: "flex" }}>
                        <ShoppingBasketIcon
                          style={{
                            maxWidth: "20px",
                            margin: "auto 0",
                            left: -8
                          }}
                        />{" "}
                        &nbsp;&nbsp;{"Orders"}
                      </h3>
                    }
                  />
                </NavLink>
              </ListItem>

              <ListItem disableRipple button key={"Wallet"}>
                <NavLink
                  className="navbar-item"
                  activeClassName="is-active"
                  to="/wallet"
                >
                  <ListItemText
                    style={{ paddingLeft: "20px" }}
                    primary={
                      <h3 style={{ display: "flex" }}>
                        <FontAwesomeIcon
                          icon={faWallet}
                          style={{
                            maxWidth: "20px",
                            margin: "auto 0",
                            left: -8
                          }}
                        />{" "}
                        &nbsp;&nbsp;{"Wallet"}
                      </h3>
                    }
                  />
                </NavLink>
              </ListItem>

              <p
                className="subtext-sm"
                style={{
                  paddingLeft: "35px",
                  marginTop: "100px"
                }}
              >
                ADMIN ONLY
              </p>
              <ListItem button key={"Create Gift"}>
                <NavLink
                  className="navbar-item"
                  activeClassName="is-active"
                  to="/creategift"
                >
                  <ListItemText
                    style={{ paddingLeft: "20px" }}
                    primary={<h3>{"Create Gift"}</h3>}
                  />
                </NavLink>
              </ListItem>
              <ListItem button key={"Login"}>
                <NavLink
                  className="navbar-item"
                  activeClassName="is-active"
                  to="/login"
                >
                  <ListItemText
                    style={{ paddingLeft: "20px" }}
                    primary={<h3>{"Login"}</h3>}
                  />
                </NavLink>
              </ListItem>
              <ListItem button key={"Products"}>
                <NavLink
                  className="navbar-item"
                  activeClassName="is-active"
                  to="/products"
                >
                  <ListItemText
                    style={{ paddingLeft: "20px" }}
                    primary={<h3>{"Products"}</h3>}
                  />
                </NavLink>
              </ListItem>
              <ListItem button key={"Checkout"}>
                <NavLink
                  className="navbar-item"
                  activeClassName="is-active"
                  to="/checkout"
                >
                  <ListItemText
                    style={{ paddingLeft: "20px" }}
                    primary={<h3>{"Checkout"}</h3>}
                  />
                </NavLink>
              </ListItem>
            </List>
            <div
              style={{
                position: "fixed",
                left: 0,
                bottom: 0
              }}
            >
              <Divider light />
              <List
                dense
                style={{
                  backgroundColor: "#fff",
                  // backgroundImage: `url(${"https://i.ibb.co/TqRZpD2/beige-paper.png"})`,
                  width: "219px"
                }}
              >
                <ListItem elevation={10}>
                  <ListItemAvatar>
                    <Avatar>
                      <img
                        alt={user.displayName + "profile picture"}
                        src={user.photoURL}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    style={{color:"#000"}}
                    primary={<Truncate lines={1}>{user.displayName}</Truncate>}
                    secondary={
                      <a
                        href="#signout"
                        onClick={signOut}
                        className="purple-text"
                        style={{ textDecoration: "none" }}
                      >
                        Sign Out
                      </a>
                    }
                  ></ListItemText>
                </ListItem>
              </List>
            </div>
          </div>
        </Drawer>
      </span>
      <span hidden={doesntmatches}>
        <AppBar color="inherit">
            <Grid style={{marginLeft:"30px"}} item xs={6}>
              <RouterLink to="/">
                <img alt="Shipwrapped logo" style={{width:"160px",padding:"12px 0px"}}src="https://drive.google.com/uc?id=1Fhc7oy52tJxEgdLzKM4_2sjdI5VYcSPm"/>
              </RouterLink>
              
              {/* <h3 style={{ color: "black" }}>
                <span
                  role="img"
                  aria-label="Present icon"
                  style={{ top: 8, fontSize: "22px" }}
                >
                  🎁&nbsp;
                </span><span className="logofont">
                Shipwrapped</span>
              </h3>
              <p className="subtext-sm" style={{ paddingLeft: "30px" }}>
                Give better gifts.
              </p> */}
            </Grid>
        </AppBar>
        <Paper
          elevation={10}
          style={{
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0,
            zIndex: "100"
          }}
        >
          <BottomNavigation
            value={page}
            onChange={(event, newPage) => {
              setPage(newPage);
            }}
            showLabels
          >
            <BottomNavigationAction
              component={NavLink}
              to="/"
              exact={true}
              label="Home"
              icon={<HomeIcon />}
            />
            <BottomNavigationAction
              component={NavLink}
              to="/orders"
              label="Orders"
              icon={<ShoppingBasketIcon />}
            />
            <BottomNavigationAction
              component={NavLink}
              to="/wallet"
              label="Wallet"
              icon={<FontAwesomeIcon icon={faWallet} style={{ marginBottom: "3px" }}/>}
            style={{ fontSize: "20px", marginTop: "2px "}}
            />
          </BottomNavigation>
        </Paper>
      </span>
    </div>
  );
};

export default NavbarMobile;
