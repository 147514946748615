import React, { useState, Fragment } from "react";
import { ExpandModal } from "react-spring-modal";
import "../styles.css";
import "react-spring-modal/styles.css";
import { auth, GoogleAuth } from "./GoogleAuth";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

function signOut() {
  auth.signOut();
}

function SettingsModal() {
  const [isOpen, setOpen] = useState(false);

  function toggleOpen() {
    console.log("Toggling to:", !isOpen);
    setOpen(!isOpen);
  }

  return (
    <Fragment>
      <IconButton
        onClick={toggleOpen}
        className="SettingsModal__open floatright"
        variant="text"
        style={{ marginTop: "20px" }}
      >
        <FontAwesomeIcon icon={faCog} />
      </IconButton>
      <ExpandModal
        isOpen={isOpen}
        onDismiss={() => setOpen(false)}
        className="SettingsModal"
        contentProps={{
          style: {
            padding: "20px 50px 30px 40px",
            maxWidth: "calc(50% - 30px)",
            height: "auto",
            maxHeight: "calc(100vh - 30px)",
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 99999
          }
        }}
      >
        <h2>Settings</h2>
        <button
          style={{ marginTop: "20px" }}
          onClick={signOut}
          className="SettingsModal__logout"
        >
          Contact Support
        </button>
        <br />
        <button onClick={signOut} className="SettingsModal__logout">
          Logout
        </button>
      </ExpandModal>
    </Fragment>
  );
}

export default SettingsModal;
