import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { green, grey } from "@material-ui/core/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 15,
        top: 50,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: "0 4px",
        color: "#000",
        height: "25px",
        width: "25px",
        backgroundColor: "rgb(105, 243, 135)",
    },
}))(Badge);

const SelectedAvatar = (product) => {
    return (
        <div style={{ marginRight: "15px" }}>
            <StyledBadge badgeContent={<FontAwesomeIcon icon={faCheck} />}>
                <Avatar
                    src={product.image}
                    alt={product.shortname}
                    variant="rounded"
                    style={{
                        height: "60px",
                        width: "60px",
                        marginRight: "15px",
                        marginLeft: "5px",
                    }}
                />
            </StyledBadge>
        </div>
    );
};
export default SelectedAvatar;

const NotSelectedAvatar = () => {
    return (
        <div>
            <Avatar
                variant="rounded"
                style={{
                    backgroundColor: grey[300],
                    marginRight: "30px",
                    height: "60px",
                    width: "60px",
                    marginLeft: "5px",
                }}
            >
                <FontAwesomeIcon
                    style={{ color: grey[200], fontSize: "30px" }}
                    icon={faTimes}
                />
            </Avatar>
        </div>
    );
};
export { NotSelectedAvatar };
