import React, { useContext } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import HomeIcon from "@material-ui/icons/Home";
import { Link as RouterLink, Route } from "react-router-dom";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import Skeleton from "react-loading-skeleton";
import OrderDetail from "./OrderDetail";
import ordersContext from "../../context/orders/ordersContext";
import Grid from "@material-ui/core/Grid";
import OrderCard from "./OrderCard";

const OrderDashboard = ({ orders, setOrderInProgress }) => {
    if (orders) {
        return (
            <>
                <h1 className="sectionheader">Your Orders</h1>
                <Grid container justifyContent="flex-start" spacing={2}>
                    {orders.map((order) => (
                        <Grid item>
                            <RouterLink
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to={"/orders/" + order.id}
                            >
                                <OrderCard
                                    order={order}
                                    setOrderInProgress={setOrderInProgress}
                                />
                            </RouterLink>
                        </Grid>
                    ))}
                </Grid>
            </>
        );
    } else {
        return <div style={{marginTop:"50px", width:"350px"}}><Skeleton count={6} style={{height:"20px", margin:"15px 0px"}}/></div>;
    }
};

const OrderMain = () => {
    const { orders, setOrderInProgress } = useContext(ordersContext);
    return (
        <>
            <Breadcrumbs>
                <RouterLink
                    style={{ textDecoration: "none", color: "black" }}
                    to={"/"}
                >
                    <p className="mutertext">
                        <HomeIcon style={{ fontSize: 14, top: 2 }} />
                        &nbsp;Home
                    </p>
                </RouterLink>
                <RouterLink
                    style={{ textDecoration: "none", color: "black" }}
                    to={"/orders"}
                >
                    <p className="mutertext">
                        <ShoppingBasketIcon style={{ fontSize: 14, top: 2 }} />
                        &nbsp;Orders
                    </p>
                </RouterLink>
            </Breadcrumbs>
            <Route exact path="/orders/">
                <OrderDashboard
                    orders={orders}
                    setOrderInProgress={setOrderInProgress}
                />
            </Route>
            <Route path="/orders/:id">
                <OrderDetail />
                <OrderDashboard
                    orders={orders}
                    setOrderInProgress={setOrderInProgress}
                />
            </Route>
        </>
    );
};

export default OrderMain;
