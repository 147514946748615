//reciepient and delivery date
import React, { useEffect, useState } from "react";
import { faUsps } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  Button,
  Grid,
  Paper,
} from "@material-ui/core";
import { get } from "lodash";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { FORM_FIELDS } from "../../utils/const";
import api from "../../apis/api";

const DeliveryDetails = ({
  shipmentDetails,
  preferredDeliveryDate,
  handleShipmentChange,
  handleDeliveryDateChange,
}) => {
  //api/recipients
  return (
    <Grid container>
      <Grid item lg={12}>
        <h1 className="sectionheader">Where's this thing headed?</h1>
      </Grid>
      <Grid item lg={12}>
        <Grid container spacing={4} direction="column" style={{ bottom: 20 }}>
          <Grid item lg={12}></Grid>
          <Grid item lg={12}>
            <form noValidate autoComplete="off">
              <h3 className="tiny-space">Gift Recipient</h3>
              <p className="subtext">
                Please enter the shipping address of your gift's final
                destination below.
              </p>
              <Grid spacing={1} container direction="row">
                {FORM_FIELDS.map((field, index) => (
                  <Grid
                    key={index}
                    item
                    lg={get(field, "lg")}
                    md={get(field, "md")}
                    xs={get(field, "xs")}
                    sm={get(field, "sm")}
                  >
                    <TextField
                      inputProps={{ style: { fontFamily: "karla" } }}
                      required
                      onChange={(e) =>
                        handleShipmentChange(e.target.value, e.target.name)
                      }
                      fullWidth
                      id="filled-basic"
                      label={<span>{field.label}</span>}
                      name={field.name}
                      value={
                        shipmentDetails[field.name]
                          ? shipmentDetails[field.name]
                          : ""
                      }
                      variant="outlined"
                    />
                  </Grid>
                ))}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12}>
        <Grid
          container
          align="start"
          spacing={4}
          style={{ paddingBottom: "40px" }}
        >
          <Grid item lg={12}>
            <h3 className="space">Preferred Delivery Date</h3>
            <p className="subtext">
              Please enter the date you would like your gift delivered to your
              Recipient. To ensure on-time delivery, make sure you get your
              items to us 5 days prior to your{" "}
              <span className="subtext-blknomargin">
                Preferred Delivery Date
              </span>
              .
            </p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={
                  preferredDeliveryDate ? new Date(preferredDeliveryDate) : null
                }
                inputVariant="outlined"
                disablePast
                disableToolbar
                onChange={(selectedDate) => {
                  handleDeliveryDateChange(selectedDate);
                }}
                openTo="date"
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeliveryDetails;
