import React from 'react';
import Stripe from "../components/Stripe";
import Braintree from "../components/BraintreeDropin";
import PromoBanner from "../components/PromoBanner";
import Grid from "@material-ui/core/Grid";

const Wallet = () => (
    <Grid>
        <PromoBanner/>
        <h1 className="sectionheader">Add a Payment Method</h1>

                <Braintree />
                {/* <Stripe/> */}

    </Grid>
);
 
export default Wallet;